import React, { FC } from 'react'
import NextHead from 'next/head'

const Logo: FC = () => {
  const data = {
    "@context": "https://schema.org/",
    "@type": "Organization",
    "name": "Noleggio a Lungo Termine",
    "url": "https://www.carplanner.com/",
    "logo": "https://static1.carplanner.com/logos/carplanner.svg",
  }

  return <NextHead>
    <script
      type="application/ld+json"
      id="structuredDataLogo"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(data),
      }}
    />
  </NextHead>
}

export default Logo;
