import { AxiosResponse } from 'axios'
import request from 'src/utils/request'
import { USERS_SERVICE_URI } from './constants'

export const getProfileApi = (userAuthId): Promise<any> =>
  request.get(`${USERS_SERVICE_URI}/user`, {
    headers: {
      Authorization: userAuthId,
    },
  })
