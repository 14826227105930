import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useDispatch } from 'react-redux'
import { useCookies } from 'react-cookie'
import queryStringManager from 'query-string-manager'
import {
  performSearchOffers as nltPerformSearchOffers,
} from 'src/redux/nlt-offer'
import {
  performSearchOffers as sellPerformSearchOffers,
} from 'src/redux/sell-offer'

export const useQuerystringToFilterOffers = (domain: 'nlt' | 'sell') => {
  const dispatch = useDispatch()
  const performSearchOffers = domain === 'nlt' ? nltPerformSearchOffers : sellPerformSearchOffers

  useEffect(() => {
    const qs = queryStringManager.read().get()

    if (Object.entries(qs).length > 0) {
      dispatch(performSearchOffers())
    }
  }, [])
}

export const useBackButton = () => {
  const onPopState = () => {
    if (queryStringManager.read().get()) {
      location.reload()
    }
  }

  useEffect(() => {
    window.addEventListener('popstate', onPopState);

    return () => {
      window.removeEventListener('popstate', onPopState);
    };
  })
}

export const useTracking = () => {
  const { query } = useRouter()
  const [validQuery, setValidQuery] = useState<any>({})
  const [allCookies, setCookie, removeCookie] = useCookies(['CP_TRACKER']);
  const path = '/'
  const today = new Date()
  const validity = parseInt(process.env.NEXT_PUBLIC_TRACKING_COOKIE_VALIDITY)
  const expires = new Date(today.setDate(today.getDate() + validity))

  useEffect(() => {
    setValidQuery(query)
  }, [query])

  const trackingData = () => allCookies['CP_TRACKER']

  const setTracking = () => {
    const {
      utm_source: source = null,
      utm_medium: medium = null,
      utm_ref: referrer = null,
      utm_campaign: campaign = null,
      utm_campaign_date: campaign_date = null,
    } = { ...query, ...validQuery }

    if ((source || medium || referrer)
      && (typeof allCookies['CP_TRACKER'] === 'undefined' || !allCookies['CP_TRACKER'])
    ) {

      setCookie('CP_TRACKER', {
        source,
        referrer,
        medium,
        campaign,
        campaign_date,
      }, { path, expires })
    }
  }

  const removeTracking = () => removeCookie('CP_TRACKER')

  return [setTracking, removeTracking, trackingData]
}
