import React, { FC } from 'react'
import NextHead from 'next/head'

interface Props {
  breadcrumbData: Array<BreadcrumbsGlobal.Item>
}

const Breadcrumb: FC<Props> = ({ breadcrumbData }) => {
  const data = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    "itemListElement": breadcrumbData.map((crumbItem, idx) => ({
      "@type": "ListItem",
      "position": idx + 1,
      "name": crumbItem.title,
      "item": `https://carplanner.com${crumbItem.href}`
    }))
  }

  return <NextHead>
    <script
      type="application/ld+json"
      id="structuredDataBreadcrumb"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(data),
      }}
    />
  </NextHead>
}

export default Breadcrumb;
