import React, { FC, useEffect } from 'react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { getCookies } from 'cookies-next';
import { useDispatch, useSelector } from 'react-redux';
import { HubspotProvider } from '@aaronhayes/react-use-hubspot-form';
import { getProfileApi } from 'server/api/user';
import { toggleLoader } from 'src/redux/common/actions'
import { getProfileFailure, getProfileSuccess } from 'src/redux/user';
import { useTracking } from 'src/utils/hooks';
import { MetaTags, Spinner } from 'src/components/common'
import { Logo } from 'src/components/common/StructuredData'

const HeaderContainer = dynamic(() => import('src/containers/HeaderContainer'))
const ResumeOfferModalContainer = dynamic(() => import('src/containers/ResumeOfferModalContainer'))
const NewsletterContainer = dynamic(() => import('src/containers/NewsletterContainer'))
const ContactContainer = dynamic(() => import('src/containers/ContactContainer'))
const FooterContainer = dynamic(() => import('src/containers/FooterContainer'))

const Wrapper = styled.div`
  text-align: center;
  background-color: #fcfbfe;

  @media (max-width: 768px) {
    & {
      margin-top: 56px;
    }
  }
`

interface Props {
  isFullScreen: boolean
  hasContactSection: boolean
  hasContactAction: boolean
  hasModalResume: boolean
  children: any
}

const Layout: FC<Props> = ({ isFullScreen, hasContactSection, hasContactAction, hasModalResume, children }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [setTracking] = useTracking()
  const router = useRouter()
  const isLoading = useSelector((state: Store.RootState) => state.common.isLoading)

  useEffect(() => {
    const cookies = getCookies();

    async function fetchUserProfile(userAuthId) {
      try {
        const profile = await getProfileApi(userAuthId);

        if (profile && profile.data && profile.data.id) {
          dispatch(getProfileSuccess({
            profile: profile.data
          }))
        } else {
          dispatch(getProfileFailure({ errors: profile.errors || 'unknown' }))
        }
      } catch (err: Errors | any) {
        dispatch(getProfileFailure({ errors: err.errors || 'unknown' }))
      }
    }

    if (cookies.userAuthId) {
      fetchUserProfile(cookies.userAuthId)
    }

    setTracking()
  }, [])

  useEffect(() => {
    document.addEventListener('click', documentClickHandler)

    dispatch(toggleLoader(false))

    router.events.on('routeChangeStart', () => dispatch(toggleLoader(true)));
    router.events.on('routeChangeComplete', () => dispatch(toggleLoader(false)));
    router.events.on('routeChangeError', () => dispatch(toggleLoader(false)));

    return () => {
      document.removeEventListener('click', documentClickHandler)
    }
  }, [])

  const documentClickHandler = event => {
    if (event.target.nodeName === 'A') {
      dispatch(toggleLoader(true))
    }
  }

  return (
    <>
      <MetaTags meta={t('common:meta', { returnObjects: true })} />
      {!isFullScreen && <HeaderContainer title={t('common:header.imageTitle')} />}
      <Spinner isVisible={isLoading} />
      <Logo />
      <HubspotProvider>
        <Wrapper>{children}</Wrapper>
        {!isFullScreen &&
          <>
            {hasContactSection &&
              <ContactContainer
                title={t('common:contact.title')}
                description={t('common:contact.description')}
                action={hasContactAction ? t('common:contact.action') : null}
              />
            }
            <NewsletterContainer
              title={t('common:newsletter.title')}
              description={t('common:newsletter.description')}
            />
            <FooterContainer />
            {hasModalResume &&
              <ResumeOfferModalContainer />
            }
          </>
        }
      </HubspotProvider>
    </>
  )
}

export default Layout
