import React, { FC } from 'react'
import NextHead from 'next/head'

interface Props {
  items: Array<any>
}

const OfferList: FC<Props> = ({ items }) => {
  const data = {
    "@context": "https://schema.org/",
    "@type": "ItemList",
    "numberOfItems": items.length,
    "itemListElement": items.map((item, idx) => ({
      "@type": "ListItem",
      "position": idx,
      "url": item
    }))
  }

  return <NextHead>
    <script
      type="application/ld+json"
      id="structuredDataOfferList"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(data),
      }}
    />
  </NextHead>
}

export default OfferList;
