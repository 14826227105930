import React, { useEffect, useState } from 'react'
import NextHead from 'next/head'
import { getFaqAutoCompleteItems } from 'server/api/nlt'

const Faq = () => {
  const [faqSingleItems, setFaqSingleItems] = useState([]);

  useEffect(() => {
    async function getFaqs() {
      const faqs = await getFaqAutoCompleteItems();

      setFaqSingleItems(faqs.data)
    }

    if (!faqSingleItems.length) {
      getFaqs()
    }
  })

  let faqItems = {};

  faqSingleItems.forEach(faqItem => {
    faqItems[faqItem.uri] = faqItems[faqItem.uri] || [];

    faqItems[faqItem.uri].push({
      ...faqItem
    })
  });

  const data = {
    "@context": "https://schema.org/",
    "@type": "FAQPage",
    "mainEntity": Object.keys(faqItems).map(faqUri => ({
      "@type": "Question",
      "url": `https://www.carplanner.com/noleggio-a-lungo-termine/faq/${faqUri}`,
      "name": faqItems[faqUri][0].title,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": faqItems[faqUri].map(faqItem => `<p><strong>${faqItem.q || ''}</strong> ${faqItem.a || ''}</p>`).join('')
      }
    })),
  }

  return <NextHead>
    <script
      type="application/ld+json"
      id="structuredDataFaq"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(data),
      }}
    />
  </NextHead>
}

export default Faq;
