import React, { FC } from 'react'
import NextHead from 'next/head'
import brands from 'src/containers/data/brands.json'

interface Props {
  offerData: OfferGlobal.RentOfferDetail
}

const NltOffer: FC<Props> = ({ offerData }) => {
  const data = {
    "@context": "https://schema.org/",
    "@type": "Vehicle",
    "name": `${offerData.vehicle.name} - Noleggio a Lungo Termine`,
    "image": offerData.images.map(image => image.src),
    "description": `${offerData.vehicle.name} a ${offerData.monthlyRate}€ al mese! Con CarPlanner hai manutenzione, assicurazione, bollo, tassa di proprietà a molti altri servizi, inclusi in un unico canone mensile.`,
    "sku": offerData.code,
    "mpn": offerData.id,
    "fuelType": offerData.vehicle.engine.fuel,
    "brand": {
      "@type": "Brand",
      "name": offerData.vehicle.brand,
      "logo": brands.find(brand => brand.href === offerData.vehicle.brandCode).image
    },
    "offers": {
      "@type": "Offer",
      "url": `https://www.carplanner.com/noleggio-a-lungo-termine/${offerData.vehicle.brandCode}/${offerData.vehicle.modelCode}/${offerData.code}`,
      "priceCurrency": 'EUR',
      "price": offerData.monthlyRate,
      "itemCondition": 'https://schema.org/NewCondition',
      "availability": 'https://schema.org/LimitedAvailability',
      "seller": {
        '@type': 'Organization',
        "name": "CarPlanner",
        "url": "https://www.carplanner.com/",
        "logo": "https://static1.carplanner.com/logos/carplanner.svg",
      },
    }
  }

  return <NextHead>
    <script
      type="application/ld+json"
      id="structuredDataNltOffer"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(data),
      }}
    />
  </NextHead>
}

export default NltOffer;
